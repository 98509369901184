export const parseJson = (json, defaultValue, join) => {
  let response = null
  try {
    response = JSON.parse(json)
    if (join === true) {
      response = { ...defaultValue, ...response }
    }
  } catch (e) {
    response = null
  }
  return response || defaultValue
}

export const camelize = function(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const removeEmpty = function (obj) {
  if(obj == null){
    return null;
  }
  if(Array.isArray(obj) && obj.length === 0){
    return null;
  }else if(Array.isArray(obj)){
    return obj.filter(v => v != null).map(v => v === Object(v) || Array.isArray(v) ? removeEmpty(v) : v)
  }
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) || Array.isArray(v) ? removeEmpty(v) : v])
  )
}
