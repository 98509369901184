import mixinBase from '@/components/mixin/mixinBase'

export default {
  mixins: [mixinBase],
  data() {
    return {
      loadingTime: null,
      pagination: { page: 1, total: 0, paginationTotal:0, size: 10 },
      sort: { direction: 'DESC', field: null },
    }
  },
  created: function () {
    this.$root.$on("system::refresh-tables", this.refresh);
  },
  beforeDestroy() {
    this.$root.$off("system::refresh-tables", this.refresh);
  },
  computed:{
    loading(){
        return this.loadingTime != null;
    },
    sortIsDesc:{
        get(){
          return this.sort.direction === 'DESC'
        },
        set(value){
          this.sort.direction = value ? 'DESC' : 'ASC'
        }
    }
  },
  methods: {
    setLoading(){
       return this.loadingTime = Date.now();
    },
    setLoaded(token){
      if (this.loadingTime === token){
        this.loadingTime = null;
      }
    },
    setPagination(page, total, size){
      this.pagination.page = page
      this.pagination.total = total;
      this.pagination.paginationTotal = total != null ? total : 1e10;
      this.pagination.size = size;
    },
    setSort(field, direction){
        this.sort.field = field;
        this.sort.direction = direction;
    },
    setMetaDataFromResult(result){
      if(result == null) return;
      this.pagination = { ...this.pagination, page: result.page, pages: result.pages, total: result.total }
      this.pagination.paginationTotal = result.total != null ? result.total : 1e10;
      this.setSort(result.sort.field, result.sort.direction)
    },
    onRowClicked(row) {
      this.$emit('selected', row.id)
    },
    onPageClick(event, page) {
        // console.log("onPageChanged", page)
        // event.preventDefault();
        this.pagination.page = page || 1;
        this.refresh();
    },
    onSortChanged(data) {
      // console.log("onSortChanged", data)
      this.setSort(data.sortBy, data.sortDesc ? 'DESC' : 'ASC');
      this.pagination.page = 1;
      this.refresh();
    },
    toggleRow(item, value){
      let selectedObjects = this.selected
      if(value !== true){
        delete selectedObjects[item.id];
        selectedObjects.all = false
      }else {
        selectedObjects[item.id] = {active:true,id:item.id,item}
        selectedObjects.all = Object.keys(selectedObjects).filter(k => k !== 'all').length === this.rows.length //TODO: MULTIPLE PAGES
      }
      if(this.forceUpdate != null)
        this.forceUpdate();
      return selectedObjects;
    },
    toggleAllRow(value){
      let selectedObjects = this.selected
      console.log("_toggleAllRow",{value})
      let $this = this;
      if (value !== true) {
        Object.keys(selectedObjects)
          .forEach(k => delete selectedObjects[k])
      } else {
        this.rows.forEach(item => {
          $this.toggleRow(item, true)
        })
      }
      selectedObjects.all = value
      if(this.forceUpdate != null)
        this.forceUpdate();
      this.selected = selectedObjects;
    },
    isIndeterminate(selectedObjects) {
      return selectedObjects.all !== true && Object.keys(selectedObjects).filter(k => k !== 'all').length > 0
    }
  },
}
