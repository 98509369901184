import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  computed: {
  },
  methods: {
    showSuccessNotification(title,message,stickId) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text:message,
          icon: 'EditIcon',
          variant: 'success',
        },
      });
    },
    showErrorNotification(title, message,stickId) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: (title || '').toUpperCase(),
          text:message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }
  }
}
